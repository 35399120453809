<template>
  <div :class="['flex', {xs12: isExportable, md6: isExportable, size: isExportable}]">

    <vac-card
      v-if="isExportable"
      :title="$t(label)"
      class="chart-widget">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        :header="label"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />
      <Actions
        v-if="isExportable"
        :active-switcher-button="activeSwitcherButton"
        show-percentage-switcher
        :export-to-excel="exportToExcel"
        @togglePreview="togglePreview"
        is-preview
        @toggle-percentage="onToggle($event)"
      />

      <Bar
        :chart-id="'bar-chart'"
        :dataset-id-key="'label'"
        :chart-data="data"
        :css-classes="'bar export'"
        :chart-options="chartOptions"
      />
    </vac-card>

    <vac-card
      v-else
      :style="{margin: '10px 0'}"
      :title="$t(label)"
      class="chart-widget">

      <Bar
        :chart-id="'bar-chart'"
        :dataset-id-key="'label'"
        :chart-data="data"
        :css-classes="'bar-small export'"
        :chart-options="chartOptions"
      />
    </vac-card>

  </div>
</template>

<script>
import HelperExcel from '../../services/Helpers/HelperExcel.js';
import Actions from "../actions/Actions.vue";
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal.vue";
import {Bar} from 'vue-chartjs/legacy'

import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: "TrendsImpressions",
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    size: {
      type: String,
      default: "xl6",
    },
    isExportable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Impressions per Retailer Stats'
    }
  },
  components: {
    Actions,
    ExcelPreviewModal,
    Bar
  },
  data() {
    return {
      isPreview: false,
      activeSwitcherButton: 1
    }
  },
  computed: {
    chartOptions() {
      return {
        // datasets: {
        //   skipNull: true
        // },
        minBarLength: 1,
        scales: {
          x: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                return value
              }
            },
            type: 'linear',
          },
          y: {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              label: (ttItem) => {
                let sum = 0
                for (let i = 0; i < Object.keys(ttItem.parsed._stacks.x).length; i++) {
                  if (ttItem.parsed._stacks.x[`${i}`]) {
                    sum += ttItem.parsed._stacks.x[i]
                  }
                }
                if (this.activeSwitcherButton === 1) {
                  return `${ttItem.dataset.label}: ${ttItem.raw}`;
                } else {
                  return `${ttItem.dataset.label}: ${(ttItem.raw * 100 / sum).toFixed(2)}%`;
                }
              }
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          },
        }
      };
    },
    preparedData() {
      const {datasets, labels} = this.data;

      const returnObj = {}
      returnObj.labels = [this.label, ...labels]
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }

      returnObj.datasets = [];
      datasets.forEach(data => {
        const obj = {
          data: [data.label]
        };
        data.data.forEach(info => {
          obj.data.push(info)
          returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(data.label).length)
          returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(info).length)
        });
        returnObj.datasets.push(obj);
      });
      let obj = {
        data: []
      }
      returnObj.labels.forEach((l, index) => {
        if (index === 0) {
          obj.data.push('Total')
        } else {
          let sum = 0
          returnObj.datasets.forEach(ds => {
            sum += ds.data[index]
          })
          obj.data.push(sum)
        }
      })
      returnObj.datasets.push(obj)
      if (this.activeSwitcherButton === 2) {
        returnObj.datasets.forEach((ds, indexDs) => {
          ds.data.forEach((d, indexD) => {
            if (indexD > 0) {
              returnObj.datasets[indexDs].data[indexD] = `${(returnObj.datasets[indexDs].data[indexD] * 100 / returnObj.datasets[(returnObj.datasets.length - 1)].data[indexD]).toFixed(2)}%`
            }
          })
        })
      }
      return returnObj;
    }
  },
  methods: {
    exportToExcel() {
      const customData = [];
      const {datasets, labels} = this.data;
      const customLabels = ["Retailer", ...labels];
      datasets.forEach(data => {
        const obj = {
          "Retailer": data.label,
        };

        labels.forEach((l, index) => {
          obj[l] = data.data[index]
        })
        customData.push(obj);
      });
      let totalObj = {
        "Retailer": 'Total',
      }
      // datasets.forEach(data => {
      //   let sum = 0
      //   labels.forEach((l, index) => {
      //     sum += data.data[index]
      //   })
      // });
      labels.forEach((l, index) => {
        let sum = 0
        // if (index > 0) {
          datasets.forEach(data => {

            sum += data.data[index]
          })
          totalObj[l] = sum
        // }
      })
      customData.push(totalObj);
      if (this.activeSwitcherButton === 2) {
        labels.forEach(l => {
          let sum = customData[(customData.length - 1)][l]
          customData.forEach(cd => {
            cd[l] = `${(cd[l] * 100 / sum).toFixed(2)}%`
          })
        })
      }
      HelperExcel.exportToExcel({}, this.label, customLabels, customData);
    },
    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    onToggle(val) {
      this.activeSwitcherButton = val
    }
  },
};
</script>

<style lang="scss">
.bar {
  height: 800px;
}

.bar-small {
  height: 400px;
}
</style>
